@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.animate-marquee {
    animation: marquee 30s linear infinite;
}